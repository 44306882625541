import React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import customRoute from './CustomRoutes';

export const ProductsSection = () => {
    const navigate = useNavigate()
  return (
    <section className="product-section my-80">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="screen-card">
                        <img src={require("../assets/images/insect.jpg")} className="img-fluid" alt=""/>
                        <div className="sc-content">
                            <h2>Insect Screens</h2>
                            <p>These screens fortify your space, making it impenetrable to determined insects. They retract on insect-free days but effectively stop insects from advancing.</p>
                            <Link to={customRoute.insect_screen.url} className="btn btn-accent-2">See Product</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="screen-card">
                        <img src={require("../assets/images/shade.jpg")} className="img-fluid" alt=""/>
                        <div className="sc-content">
                            <h2>Shade Screens</h2>
                            <p>These screens block up to 97% of the sun's rays while also making a substantial dent in your energy costs.</p>
                            <Link to={customRoute.shade_screen.url} className="btn btn-accent-2">See Product</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="screen-card">
                        <img src={require("../assets/images/clear.jpg")} className="img-fluid" alt=""/>
                        <div className="sc-content">
                            <h2>Clear Vinyl</h2>
                            <p>These screens maintain the wide-open view you want from your outdoor space, while protecting you from plunging temps or pouring rain.</p>
                            <Link to={customRoute.clear_vinyl.url} className="btn btn-accent-2">See Product</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="screen-card">
                        <img src={require("../assets/images/hurricane.jpg")} className="img-fluid" alt=""/>
                        <div className="sc-content">
                            <h2>Hurricane Screens</h2>
                            <p>Our hurricane screens are the backbone of our whole product line. We developed them to be strong, attractive, UV-resistant, and ultimately reliable.</p>
                            <Link to={customRoute.hurricane.url} className="btn btn-accent-2">See Product</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>      
    </section>
  )
}
