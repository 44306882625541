import React from 'react';

const ArrowButton = () => {
  return (
      <div className="centered-container" >
        <div className="swiper-button-prev swiper-btn-prev"  aria-label="Previous slide" ></div>
        <div className="swiper-button-next swiper-btn-next"  aria-label="Next slide" ></div>
    </div>
  )
}

export default ArrowButton
