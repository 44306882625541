import React from 'react';

export const PageSectionHeader = (props) => {
  return (
    <div>
        <section className="product-page-nav">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="navigation">
                            <ul>
                                {props.dataset.map((data,index)=>(
                                    <li key={index}><div onClick={()=>props.handleClick(data)}>{data}</div></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
