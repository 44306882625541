import React,{useContext} from 'react';
import '../Styles/about.css';
import { Topbar } from '../Components/Topbar';
import { Header } from '../Components/Header';
import { Footer } from '../Components/Footer';
import USAIconPrimary from "../assets/images/usa-icon-primary.svg";
import TechIconPrimary from "../assets/images/technolog-icon-primary.svg";
import HurricanIconPrimary from "../assets/images/hurricane-icon-primary.svg";
import BuildIconPrimary from "../assets/images/build-icon-primary.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import { Navigation,A11y } from 'swiper/modules';
import { EffectCoverflow } from 'swiper/modules';
import ArrowButton from '../Components/ArrowButton';
import { UnmatchedExSlider } from '../Components/UnmatchedExSlider';
import { Estimateform } from '../Components/Estimateform';
import AppContext from '../AppContext';
import Helmet from 'react-helmet';

export const Products = () => {
    const {dealer,dealerData,dealerStatus} = useContext(AppContext)
  return (
    <div>
        {dealerStatus&&(<Helmet>
            <title>All Products {`${dealer && dealer.company_name ? "- "+dealer.company_name : "" }`} | Fenetex Screens</title>
            <meta name='description' content={`${dealerData?.meta_tags.product}`} />
        </Helmet>)}
        <header className='header-bar'>
            <Topbar dealeropp={true} />
            <Header />
        </header>
        <section className="home-banner-section">
            <div className="banner-bg">
                <img src={require("../assets/images/banner-products.png")} className="img-fluid banner-img" alt="" />
                <div className="home-banner-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="banner-content">
                                <div className="banner-title">
                                    <h1>Modern and Prestigious Home <span>Solutions</span></h1>
                                </div>
                                <div className="banner-left-form mt-3">
                                    <h3>Request a FREE Estimate</h3>
                                    <Estimateform dealer={dealer} />
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </section>
        <section className="product-why-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="wf-col">
                                    <img src={USAIconPrimary} alt="" />
                                    <h3>Made in USA</h3>
                                    <p>We source our materials from the best manufacturers in the USA</p>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="wf-col">
                                    <img src={TechIconPrimary} alt="" />
                                    <h3>Best Technology</h3>
                                    <p>We have been revolutionizing the retractable screen industry for almost a decade.</p>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="wf-col">
                                    <img src={HurricanIconPrimary} alt="" />
                                    <h3>Hurricane Strong</h3>
                                    <p>Our MagForce tracks and ad- vanced hybrid ballistic fabrics withstand 150+MPH winds.</p>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="wf-col">
                                    <img src={BuildIconPrimary} alt="" />
                                    <h3>Built to Last</h3>
                                    <p>Marine-grade materials that resist corrosion, rust, and screen failure.</p>
                                </div>
                            </div>
                        </div>
                    </div>          
                </div>
            </div>
        </section>  

        <section className="my-80 pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 mb-4">
                        <div className="section-title-light text-center">
                            <h2 className="heading">Product Offerings</h2>
                            <p>Each Fenetex product line has been developed and tested over the years to meet the rigorous demands of our discerning clientele. When it comes to screens, one size does not fit all. Each screen type solves specific problems.</p>
                            <p>Different screen fabrics, track types, motor types, and mounting options can be used to tailor a solution to your unique needs.</p>
                            <p>See how Fenetex products can help you. Browse our specialty screen types over the following pages to understand how.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="screen-card">
                            <img src={require("../assets/images/insect.jpg")} className="img-fluid" alt="" />
                            <div className="sc-content">
                                <h2>Insect Screens</h2>
                                <p>Upgrade your space with Fenetex insect screens – a perfect blend of durability, style, and protection. Say goodbye to bugs, hello to comfort. Elevate your environment today!</p>
                                <a href="#" className="btn btn-accent-2">Contact Dealer</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="screen-card">
                            <img src={require("../assets/images/shade.jpg")} className="img-fluid" alt="" />
                            <div className="sc-content">
                                <h2>Shade Screens</h2>
                                <p>Elevate your space with Fenetex shade screens. Experience optimal sun protection, energy efficiency, and enhanced privacy. Embrace a cooler, more comfortable environment.</p>
                                <a href="#" className="btn btn-accent-2">Contact Dealer</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="screen-card">
                            <img src={require("../assets/images/clear.jpg")} className="img-fluid" alt="" />
                            <div className="sc-content">
                                <h2>Clear Vinyl</h2>
                                <p>Experience clarity and protection with Fenetex clear screens. Enjoy unobstructed views, natural light, and a shield against the elements. Elevate your space – choose clarity.</p>
                                <a href="#" className="btn btn-accent-2">Contact Dealer</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="screen-card">
                            <img src={require("../assets/images/hurricane.jpg")} className="img-fluid" alt="" />
                            <div className="sc-content">
                                <h2>Hurricane Screens</h2>
                                <p>Secure your peace of mind with Fenetex hurricane screens. Unmatched protection against storms, durability, and ease of use. Safeguard your home effortlessly. Invest in reliable hurricane defense today!</p>
                                <a href="#" className="btn btn-accent-2">Contact Dealer</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      
        </section>

        {/* <section className="explore-tech-sol py-80">
            <div className="container">
                <div className="row mb-3">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h6>EXPLORE OUR TECHNOLOGY SOLUTIONS</h6>
                        <p>We offer revolutionary retractable screens using our MagForce Track (patent pending) and patented Quiet Spring Technology at the core of our One-Track system</p>
                    </div>
                    <div className="col-lg-8 offset-lg-2 text-center mt-4 mb-5">
                        <div className="ets-imgs">
                            <div className="ets-img">
                                <a href="#"><img src={require("../assets/images/welding-process.png")} alt="" /></a>
                            </div>
                            <div className="ets-img">
                                <a href="#"><img src={OneTrackLogoGray} alt="" /></a>
                            </div>
                            <div className="ets-img">
                                <a href="#"><img src={MagForceLogoGray} alt="" /></a>
                            </div>
                            <div className="ets-img">
                                <a href="#"><img src={require("../assets/images/Common-features.png")} alt="" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

        <section className="lifetime-warranty mt-0 py-80 text-center">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <img src={require("../assets/images/warranty.png")} alt="" />
                        <h2 className="elementor-heading-title elementor-size-default">The <span>only company </span>to offer a lifetime warranty.</h2>
                        <p>Choose Fenetex for unmatched quality and confidence with our exceptional Lifetime Warranty.</p>
                        <a href="#" className="btn btn-accent">Request a FREE Estimate</a>
                    </div>
                </div>
            </div>
        </section>

        <section className="project-sec py-80">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title-dark text-center mb-4">
                            <h4 className="sub-heading">OUR PROJECTS</h4>
                            <h2 className="heading">Perfect for your Home</h2>
                        </div>
                    </div> 
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <ArrowButton />
                    <Swiper
                        modules={[EffectCoverflow, Navigation]}
                        initialSlide={3}
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        loop={true}
                        className="project-slider"
                    >
                        <SwiperSlide>
                            <img src={require("../assets/images/project-1.png")} className="img-fluid" alt='' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={require("../assets/images/project-2.png")} className="img-fluid" alt='' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={require("../assets/images/project-3.png")} className="img-fluid" alt='' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={require("../assets/images/project-4.png")} className="img-fluid" alt='' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={require("../assets/images/project-5.png")} className="img-fluid" alt='' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={require("../assets/images/project-6.png")} className="img-fluid" alt='' />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </section>  

        {/* <UnmatchedExSlider /> */}
        <Footer />
    </div>
  )
}

// export default Products
