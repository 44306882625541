import React,{useContext} from 'react'
import { useRef } from 'react';
import { Topbar } from '../../Components/Topbar'
import { Header } from '../../Components/Header'
import { Footer } from '../../Components/Footer'
import BuiltLast  from '../../assets/icons/built-last.svg'
import Weather  from '../../assets/icons/weather-protection.svg'
import EasyAdjust  from '../../assets/icons/easy-adjust.svg'
import StopBug  from '../../assets/icons/stop-bug.svg'
import { ContactInfo } from '../../Components/ContactInfo'
import { ProductTitle } from '../../Components/ProductComponents/ProductTitle'
import { ProductFeature } from '../../Components/ProductComponents/ProductFeature'
import { ProductFeatureIcon } from '../../Components/ProductComponents/ProductFeatureIcon'
import { CompanyBadge } from '../../Components/CompanyBadge'
import { TotalControl } from '../../Components/ProductComponents/TotalControl'
import { ImageGallary2 } from '../../Components/ImageGallary2'
import { PageSectionHeader } from '../../Components/ProductComponents/PageSectionHeader'
import Helmet from 'react-helmet';
import AppContext from '../../AppContext';

export const InsectScreen = () => {
    const {dealer,dealerData,dealerStatus} = useContext(AppContext)
    const features = useRef(null);
    const warraty = useRef(null);
    const control = useRef(null);
    const option = useRef(null);
    const gallery = useRef(null);
    const reqAnEstimate = useRef(null);
    const handleClick = (type) => {
        if(type=="FEATURES"){
            features.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="WARRANTY"){
            warraty.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="TOTAL CONTROL"){
            control.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="OPTION"){
            option.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="GALLERY"){
            gallery.current?.scrollIntoView({ behavior: 'smooth' });
        }else if(type=="REQUEST AN ESTIMATE"){
            reqAnEstimate.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };
  return (
    <div>
        {dealerStatus&&(<Helmet>
            <title>Insect Screens {`${dealer && dealer.company_name ? "- "+dealer.company_name : "" }`} | Fenetex Screens</title>
            <meta name='description' content={`${dealerData?.meta_tags?.insect}`} />
        </Helmet>)}
        <header className='header-bar'>
            <Topbar dealeropp={true} />
            <Header />
        </header>

        <ProductTitle 
            image={require("../../assets/images/insect-screen-banner.png")} 
            title="Insect Screens" 
            desc="Fenetex Retractable Insect Screens protect your outdoor space from intruding bugs, ensuring it remains pristine and undisturbed." 
            handleClick={handleClick}
        />
        
        <PageSectionHeader
            dataset={["FEATURES","WARRANTY","TOTAL CONTROL","OPTIONS","GALLERY","REQUEST AN ESTIMATE"]}
            handleClick={handleClick}
        />
        
        
        <ProductFeatureIcon 
            dataset={[
                {
                    icon: BuiltLast,
                    title: "Built to Last"
                },
                {
                    icon: Weather,
                    title: "Weather Protection"
                },
                {
                    icon: EasyAdjust,
                    title: "Easily Adjustable"
                },
                {
                    icon: StopBug,
                    title: "Stops Bugs"
                }
            ]} 
        />

        <div className='feature-container' ref={features}>
            <ProductFeature
                dataset={{
                    title:"Features of Retractable Insect Screens",
                    desc:"<strong className='text-white'>Insect screens fortify your space, creating an impenetrable barrier against determined insects.</strong> Whether for your residential or commercial patio, garage door openings, cabanas, or outdoor kitchens, these screens excel in insect protection.",
                    image:require("../../assets/images/features-1.png"),
                    features:[
                        {
                            title:"Built to last",
                            slug:"build-last",
                            desc:"Our insect screens utilize a welded end retention system, ensuring longevity and durability against winds up to 75 MPH."
                        },
                        {
                            title:"Total control",
                            slug:"total-control",
                            desc:"Motorized insect screens deployment and smart home integration offer convenient and reliable protection in any condition."
                        },
                        {
                            title:"Easily Adjustable",
                            slug:"easy-adjust",
                            desc:"Our two-part adjustable track system prevents wrinkling and sticking, providing a lifetime of hassle-free performance."
                        },
                        {
                            title:"Double Protection",
                            slug:"double-protection",
                            desc:"Pair our Retractable Insect Screens with other Fenetex screens for customized and independent solutions."
                        }
                    ]
                }}
            />
        </div>

        <div style={{marginTop: "-150px"}} ref={warraty}><CompanyBadge  /></div>
        <div ref={control}>
            <TotalControl
                dataset={
                    {
                        title:"Total Control",
                        desc:"Our insect protection solution can be motorized to deploy at the touch of a button. For ultimate peace of mind, they can be paired with smart home automation systems that can be operated and monitored from anywhere in the world or even deploy on their own when disaster strikes",
                        image:require("../../assets/images/controlRemoto.png"),
                        features:[
                            {
                                title: "Remote Control with App",
                                slug: "remote-control",
                                desc: "Using Fenetex’s Bond Pro App, you can control your retractable screens, pergola roofs, lights, fans, and more without having to use a proprietary remote control for each device."
                            },
                            {
                                title: "Total control",
                                slug: "total-control",
                                desc: "We’ve successfully integrated our screens with all the major home automation brands. At first, we were pioneers, then we became experts."
                            },
                            {
                                title: "Easily Adjustable",
                                slug: "easily-adjustable",
                                desc: "Our beautiful handheld remote works with our Fenetex Smart Motors and can control up to 15 screens independently or an unlimited number of screens within 15 zones"
                            },
                            {
                                title: "Double Protection",
                                slug: "double-protection",
                                desc: "We stock three brands of motors that are used depending on customer’s needs and the application. All motors are specified when ordering screens though our online ordering portal."
                            }
                        ]
                    }
                }
            />
        </div>

        <section className="product-options py-80" ref={option}>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="section-title-light mb-4">
                            <h2 className="heading">Retractable Insect Screen Options</h2>
                        </div>
                        <p>We offer two different densities of insect screens depending on what bugs you want to keep out – a tighter weave to stop small insects like no-see-ums and a slightly more open weave to block mosquitoes.</p>
                    </div>
                    <div className="col-md-6 offset-md-2">
                        <img src={require("../../assets/images/mosquito.png")} width="130" className="me-2" alt=""/>
                        <img src={require("../../assets/images/insect-nsu.png")} width="130" className="me-2" alt=""/>
                        <img src={require("../../assets/images/options/options-1.png")} width="130" alt=""/>
                    </div>
                </div>
            </div>
        </section>

        <div ref={gallery}>
            <ImageGallary2 
                dataset={[
                    {image:require("../../assets/images/Mask-group.png")},
                    {image:require("../../assets/images/04.png")},
                    {image:require("../../assets/images/5-1.png")},
                    {image:require("../../assets/images/02.png")},
                    {image:require("../../assets/images/03.png")},
                ]}
            />
        </div>
        <div ref={reqAnEstimate}>
            <ContactInfo />
        </div>

        {/* <section className="faqs py-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="section-title-light text-center mb-4">
                            <h2 className="heading">Frequently Asked Questions</h2>
                            <p>Choosing the right insect screen for your needs can be tricky, but Fenetex offers a variety of options to suit different preferences and budgets. Here's a quick breakdown of some key questions about Fenetex insect screens</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="accordion accordion-flush" id="accordion-style-two">
                            <div className="accordion-item">
                                <h3 className="accordion-header">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-one">
                                    What type of insect screen is best?
                                </button>
                                </h3>
                                <div id="faq-one" className="accordion-collapse collapse" data-bs-parent="#accordion-style-two">
                                <div className="accordion-body"><p>There's no single "best" type, as it depends on your specific needs. Consider factors like:</p>
                                <ul>
                                    <li>Insect size: Fenetex offers various mesh sizes, from micro-mesh for tiny gnats to standard mesh for common pests.</li>
                                    <li>Durability: Consider fenetex screens for extreme weather resistance or standard polyester mesh for everyday use.</li>
                                    <li>Operation: Choose manual, motorized, or even smart home-integrated retraction systems for ultimate convenience.</li>
                                </ul>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-two">
                                    How long do insect screens last?
                                </button>
                                </h3>
                                <div id="faq-two" className="accordion-collapse collapse" data-bs-parent="#accordion-style-two">
                                <div className="accordion-body"><p>Fenetex screens are built to last! You can expect:</p>
                                <ul>
                                    <li>Frames: Up to 25 years with aluminum frames and proper maintenance.</li>
                                    <li>Mesh: Around 5-10 years for standard mesh, and even longer for Phifer® mesh with a 10-year warranty.</li>
                                </ul>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-three">
                                    What is the difference between sun screens and bug screens?
                                </button>
                                </h3>
                                <div id="faq-three" className="accordion-collapse collapse" data-bs-parent="#accordion-style-two">
                                <div className="accordion-body"><p>Both offer benefits but differ in purpose:</p>
                                <ul>
                                    <li>Sun Screens: Primarily block sunlight and heat, reducing glare and lowering energy costs. They may have larger mesh openings, allowing some smaller insects through.</li>
                                    <li>Bug Screens: Designed specifically to keep insects out, with finer mesh that blocks even tiny pests. They may offer less airflow and light transmission compared to sun screens.</li>
                                </ul>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-four">
                                    How big can you make a screen?
                                </button>
                                </h3>
                                <div id="faq-four" className="accordion-collapse collapse" data-bs-parent="#accordion-style-two">
                                <div className="accordion-body"><p>We can make screens from 3’ to 25’ wide with a maximum height over 17’.</p>
                                <p>(Hurricane screens may require additional engineering if load bearing span exceeds product approval.)</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header">
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-five">
                                    Do the retractable insect, shade, or weather screens use zippers for end retention?
                                </button>
                                </h3>
                                <div id="faq-five" className="accordion-collapse collapse" data-bs-parent="#accordion-style-two">
                                <div className="accordion-body"><p>No. We’ve reached past the low-hanging fruit of the zipper-based retention screen, which tends toward weakness and blowout, to produce retractable screens that, despite their sleek design, can stand up to whatever nature throws at them Fenetex developed a thermally fused solid end retention bead that eliminates a sewn or glued zipper and their problems.</p>
                                <p>Our screens do not bind, kink, pull-out of the tracks or break down from the sun. While not intended for severe weather protection (for that, see our retractable hurricane screens), Fenetex screens will stand up to inclement weather and you won’t have to worry about them if left down on a windy day.</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

        <Footer />
    </div>
  )
}
