import {Header} from '../Components/Header';
import {Topbar} from '../Components/Topbar';
import React from 'react';
import { Footer } from '../Components/Footer';
import "../Styles/fenetexhome.css";
import Background from '../assets/images/banner-scaled.jpg';
import { CompanyBadge } from '../Components/CompanyBadge';
import { ContactInfo } from '../Components/ContactInfo';
import { HomeBanner } from '../Components/HomeBanner';
import { AboutSection } from '../Components/AboutSection';
import { ProductsSection } from '../Components/ProductsSection';
import { RevolutonSection } from '../Components/RevolutonSection';
import { CustomiseOption } from '../Components/CustomiseOption';
import { UnmatchedExSlider } from '../Components/UnmatchedExSlider';
import { WhyFenetex } from '../Components/WhyFenetex';

export const CityHome = () => {
  return (
    <div>
            <header className='header-bar'>
                <Topbar dealeropp={true} />
                <Header />
            </header>
            
            <HomeBanner banner_image={Background} />
            <AboutSection 
                image={require("../assets/images/about.png")} 
                title="Fenetex Screens for North Western Dallas" 
                desc="Discover unparalleled comfort and style with Fenetex Screens in North Western Dallas. Elevate your living space with these innovative screens that seamlessly blend functionality and aesthetics. Enjoy unobstructed views while keeping pests at bay, thanks to the state-of-the-art design. Fenetex Screens provide a sophisticated solution for enhancing your outdoor experience, whether it's a cozy evening on the porch or entertaining guests. Embrace the perfect combination of form and function, bringing a touch of modern luxury to your North Western Dallas home." 
            />

            <ProductsSection />

            <RevolutonSection 
                title={"Revolutionize your outdoor space with Fenetex's retractable screens. Elevate comfort"}
                desc={"Discover unparalleled convenience and security with Fenetex screens. Our screens, renowned for their easy installation and adjustment on any structure, seamlessly blend strength, reliability, and durability. Fenetex's retractable screens cater to diverse needs—from patio and porch to screen doors, bug screens, folding doors, aluminum doors, patio doors, sliding doors, swing doors, swinging doors, motorized retractable screens, and retractable impact screens. Effortlessly hidden yet deployable with a touch, they integrate into smart home systems for remote operation, offering a cutting-edge solution tailored to your lifestyle."}
            />

            <CompanyBadge />

            <WhyFenetex />

            <section className="request-estimate">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <h3 className="text-end">Request a FREE estimate today</h3>
                        </div>
                        <div className="col-lg-5 text-center">
                            <a href="#" className="btn btn-accent">Get a FREE Estimate</a>
                        </div>
                    </div>
                </div>
            </section>

            <CustomiseOption /> 

            {/* <UnmatchedExSlider /> */}

            <ContactInfo />

            <Footer city={true} />     
        </div>
  )
}
