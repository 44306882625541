import React from 'react';
import customRoute from '../Components/CustomRoutes';
import { useNavigate } from 'react-router';
import ReactGA from "react-ga4";
// import Helmet from 'react-helmet';

export const ThankYou = () => {
    // React.useEffect(()=>{
    //     ReactGA.event({
    //         category: 'conversion',
    //         action: 'send_to',
    //         label: 'AW-16483442551/opbKCNiswaQZEPe-9bM9'
    //     });
    // },[])
    const navigate=useNavigate();
    return (
        <div className='thank-you-container'>
            {/* <Helmet>
                <script> 
                    {window.gtag('event', 'conversion', {'send_to': 'AW-16483442551/opbKCNiswaQZEPe-9bM9'})} 
                </script>
            </Helmet> */}
            <div className='text-center'>
            <i class="fa fa-check-circle"></i>
                <h1>Thank You</h1>
                <div>Your request was successfully submitted</div>
                <div>We'll contact you shortly</div>
                <button className="btn btn-secondary mt-4" onClick={()=>navigate(customRoute.home.url)}>Back to Home</button>
            </div>
        </div>
    )
}
