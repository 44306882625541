import React,{useContext,useRef} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import AppContext from '../AppContext';
import PhoneInput from "react-phone-input-2"; 
import "react-phone-input-2/lib/style.css";
import { ToastMessage } from './ToastMessage';
import { useNavigate } from 'react-router';
import customRoute from './CustomRoutes';
import {Hourglass} from 'react-loader-spinner';

export const ContactInfo = () => {
    const {project,dealer,dealerStatus} = useContext(AppContext)
    const [name,setName]=React.useState('');
    const [email,setEmail]=React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [desc,setDesc] = React.useState("");
    const [proNeeds,setProNeeds] = React.useState("")
    const [validated, setValidated] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [button,setButton] = React.useState(false)
    const [zipCode, setZipCode] = React.useState("");
    const [apiMessage,setapiMessage] = React.useState('The email must contain a unique value.');
    const [dialCode,setDialCode] = React.useState("")
    const [timer, setTimer] = React.useState(null)
    const [id,setId] = React.useState("")
    const contactform = useRef();
    const navigate=useNavigate()

    const reset=()=>{
        setName("")
        setEmail("")
        setPhoneNumber("")
        setDesc("")
        setProNeeds("")
        setValidated(false)
        setZipCode("")
        setButton(false)
    }
    const handleForm =(e)=>{
        setButton(true)
        const form = e.currentTarget;
        e.preventDefault();
        let contact=phoneNumber?.replace(`+${dialCode}`,"")
        contact=contact.includes("+")?contact.replace("+",""):contact
        if (form.checkValidity() === false) {
            e.stopPropagation();
        } else if(contact.length==0||contact.length<7){
            e.stopPropagation();
            setapiMessage("Contact number is required field!")
            setShow(true)
        } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,5}$/.test(email)){
            e.stopPropagation();
            setapiMessage("Entered Email id is invalid!")
            setShow(true)
        } else if(zipCode&&zipCode.length<5){
            e.stopPropagation();
            setapiMessage("Entered Zipcode is invalid!")
            setShow(true)
        } else{
            axios.post(process.env.REACT_APP_API_URL+"api/dealer_crm_lead",{
                name: name,
                email: email,
                phone_no: "+1"+phoneNumber,
                project_id: proNeeds,
                dealer_id: window.location.pathname.includes("dealer")?dealer.id:null,
                note_description: desc,
                type:window.location.pathname.includes("dealer")? "dealer_crm_lead" : "fenetex",
                zipcode:zipCode,
                form:"Bottom",
                id:id
            },{
                headers: {
                    "Access-Control-Allow-Headers" : "Access-Control-Allow-Headers",
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin":" *",
                    authtoken: process.env.REACT_APP_API_TOKEN
                }
            })
            .then((res)=>{
                if([200,201].includes(res.status)){
                    setapiMessage("Thank you for submitting you information, and we will get back to you soon.")
                    setShow(true)
                    reset()
                    window.location.replace(customRoute.thank_you_2.url)
                }else{
                    if(res.response.data.messages.error?.email){
                        setapiMessage(res.response.data.messages.error?.email ? res.response.data.messages.error.email:"")
                    }else if(res.response.data.messages.error?.phone_no){
                        setapiMessage(res.response.data.messages.error?.phone_no ? res.response.data.messages.error.phone_no:"")
                    }
                    setShow(true)
                    setButton(false)
                }
            })
            .catch((res)=>{
                if(res.response.data.messages.error?.email){
                    setapiMessage(res.response.data.messages.error?.email ? res.response.data.messages.error.email:"")
                }else if(res.response.data.messages.error?.phone_no){
                    setapiMessage(res.response.data.messages.error?.phone_no &&res.response.data.messages.error?.phone_no=="Phone is Required" ? "Phone number is required field":"")
                }
                // setapiMessage(res.response.data.messages.error)
                setShow(true)
                setButton(false)
            })
        }
    }

    const handlecall=()=>{
        clearTimeout(timer)
        const delayDebounceFn = setTimeout(() => {
            let payload={
                name: name,
                email: email,
                phone_no: "+1"+phoneNumber,
                project_id: proNeeds,
                dealer_id: window.location.pathname.includes("dealer")?dealer.id:null,
                note_description: desc,
                type:window.location.pathname.includes("dealer")? "dealer_crm_lead" : "fenetex",
                zipcode:zipCode,
                form:"Top"
            }
            if(id!=""){
                payload={
                    ...payload,
                    id:id
                }
            }
            axios.post(process.env.REACT_APP_API_URL+"api/dealer_capture_leads",{...payload},{
                headers: {
                    "Access-Control-Allow-Headers" : "Access-Control-Allow-Headers",
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin":" *",
                    authtoken: process.env.REACT_APP_API_TOKEN
                }
            })
            .then((res)=>{
                console.log(res);
                if([200,201].includes(res.data.status)){
                    setId(res.data.id)
                }
            }).catch((res)=>{
                console.log(res);
            })
            
        }, 1000)

        setTimer(delayDebounceFn)
    }

    return (
        <div>
            <section className="haq-section py-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="haq-left">
                                <h2>Have a question, or <br/>looking for a FREE <br/>estimate?</h2>
                                <p>To locate an authorized Fenetex dealer near you or to get more information, please complete this form. A member of our expert technician team will be more than happy to assist you.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="haq-right">
                                <h3>Your Information</h3>
                                <Form validated={validated} onSubmit={handleForm}>
                                    <div className="row">
                                    <Form.Group className="col-md-12 form-group">
                                        <Form.Control type="text" className="form-control data-hj-allow" name="full-name" placeholder="Full Name*" value={name} onChange={(e)=>{
                                            if(/^[A-Za-z]( ?[A-Za-z] ?)*$/g.test(e.target.value)){
                                                setName(e.target.value)
                                            }else if(e.target.value==""){
                                                setName(e.target.value)
                                            }
                                            if(dealerStatus){
                                                handlecall()
                                            }
                                        }} required />
                                    </Form.Group>
                                    </div>
                                    <div className="row">
                                        <Form.Group className="col-md-6 form-group">
                                            <Form.Control type="email" className="form-control data-hj-allow" name="email" placeholder="E-mail*" value={email} onChange={(e)=>{
                                                setEmail(e.target.value)
                                                if(dealerStatus){
                                                    handlecall()
                                                }   
                                            }} required />
                                        </Form.Group>
                                        <Form.Group className="col-md-6 form-group">
                                            {/* <ReactIntlTelInput
                                                // inputProps={inputProps}
                                                intlTelOpts={intlTelOpts}
                                                onPhoneNumberChange={setContact}
                                                value={phoneNumber} 
                                                onChange={onChange}
                                                max={10}
                                                required
                                            /> */}
                                            <PhoneInput 
                                                country={"us"} 
                                                className="data-hj-allow" 
                                                onlyCountries={["us"]}
                                                value={phoneNumber} 
                                                placeholder='Phone*'
                                                disableCountryCode={true}
                                                name="phone"
                                                onChange={(e,value)=>{
                                                    setPhoneNumber(e)
                                                    setDialCode(value.dialCode)
                                                    if(dealerStatus){
                                                        handlecall()
                                                    }
                                                }} 
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="row">
                                        <Form.Group className="col-md-6 form-group">
                                            <Form.Select name="project-type" id="" className="form-control data-hj-allow" value={proNeeds} onChange={(e)=>{
                                                setProNeeds(e.target.value)
                                                if(dealerStatus){
                                                    handlecall()
                                                }
                                            }} required>
                                                <option className='contact-option' value="" disabled>Project Type*</option>
                                                {project.length>0&&project?.map((d,index)=>(
                                                    <option className='contact-option' key={index} value={d.id}>{d.title}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="col-md-6 form-group">
                                            <Form.Control type="number" className="form-control data-hj-allow" placeholder="Zip Code*" value={zipCode} onChange={(e)=>{
                                                if(e.target.value.length<=5){
                                                    setZipCode(e.target.value)
                                                }
                                                if(dealerStatus){
                                                    handlecall()
                                                }
                                            }} required />
                                        </Form.Group>
                                    </div>
                                    <div className="row">
                                        <Form.Group className="col-md-12 form-group">
                                            <textarea name="" id="" cols="30" rows="6" className="form-control data-hj-allow" placeholder="Brief description of your project" value={desc} onChange={(e)=>{
                                                setDesc(e.target.value)
                                                if(dealerStatus){
                                                    handlecall()
                                                }
                                            }}></textarea>
                                        </Form.Group>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 contact-form-submit section-btn d-flex justify-content-between align-items-center">
                                            <Button type='submit' disabled={button} className="btn btn-accent mb-3">
                                                Get a FREE Estimate {' '}
                                                <Hourglass
                                                    visible={button}
                                                    height="20"
                                                    width="20"
                                                    ariaLabel="hourglass-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    colors={['#306cce', '#72a1ed']}
                                                />
                                            </Button>
                                            <div className='d-flex justify-content-end'>
                                                <ToastMessage position={"bottom-end"} show={show} setShow={setShow} message={apiMessage} />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
