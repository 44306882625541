import React from 'react';
import { Topbar } from '../Components/Topbar';
import { Header } from '../Components/Header';
import { Footer } from '../Components/Footer';
import customRoute from '../Components/CustomRoutes';
import { useNavigate } from 'react-router';
import ReactGA from "react-ga4";

export const PrivacyPolicy = () => {
    
    const navigate=useNavigate();
    return (
        <div className='privacy-policy-container'>
            <header className='header-bar'>
          <Topbar dealeropp={true} />
          <Header />
      </header>
      <section className='privacy-policy-text'>
      <div className='container'>
        <div className='row'>
            <div className='col-md-12'>
                <h1 className='mt-5'>Privacy Policy</h1>
                <div className="mt-4 mb-5">
                    <h6 className="mb-3 lh-base fw-normal">
                        This Privacy Policy describes how Fenetex Screens ("we", "us", or "our") collects, uses, and discloses information when you visit and interact with our website www.fenetexscreens.com (the "Site"). This Privacy Policy applies to information
                        collected through the Site and does not apply to information collected offline or through any other means.
                    </h6>
                    <h5>1. Information We Collect:</h5>
                    <p>
                        Personal Information:<br />
                        We may collect personal information that you voluntarily provide to us when you interact with the Site. This may include:<br />
                        &nbsp;<br />
                        - Contact Information: such as your name, email address, phone number, and mailing address.<br />
                        - Account Information: if you create an account with us, we may collect your username, password, and other account-related information.<br />
                        - Communication Information: including any information you provide when you contact us through the Site, such as your inquiries, feedback, or support requests.<br />
                        &nbsp;<br />
                        Automatically Collected Information:<br />
                        When you visit the Site, we may automatically collect certain information about your device and usage of the Site, including:<br />
                        &nbsp;<br />
                        - Log Data: such as your IP address, browser type, operating system, and pages visited.<br />
                        - Cookies and Similar Technologies: we may use cookies, web beacons, and other tracking technologies to collect information about your interactions with the Site. You can control cookies through your browser settings, but please
                        note that disabling cookies may limit your ability to use certain features of the Site.
                    </p>
                    <h5>2. Use of Information:</h5>
                    <p>
                        We may use the information we collect for the following purposes:<br />
                        &nbsp;<br />
                        - To provide and improve the Site and our services.<br />
                        - To respond to your inquiries and provide customer support.<br />
                        - To communicate with you about our products, services, promotions, and updates.<br />
                        - To personalize your experience on the Site and deliver relevant content and advertisements.<br />
                        - To detect, prevent, and address technical issues, fraud, or security concerns.
                    </p>
                    <h5>3. Disclosure of Information:</h5>
                    <p>
                        We may disclose your information to third parties in the following circumstances:<br />
                        &nbsp;<br />
                        - With your consent or at your direction.<br />
                        - To our service providers and partners who assist us in operating the Site and providing our services.<br />
                        - In response to a legal obligation, court order, or government request.<br />
                        - To protect the rights, property, or safety of Fenetex Screens, our users, or others.
                    </p>
                    <h5>4. Your California Privacy Rights:</h5>
                    <p>
                        &nbsp;If you are a California resident, you may have certain rights under the California Consumer Privacy Act ("CCPA"). These rights may include the right to:<br />
                        &nbsp;<br />
                        - Request access to your personal information.<br />
                        - Request deletion of your personal information.<br />
                        - Opt-out of the sale of your personal information.<br />
                        &nbsp;<br />
                        To exercise these rights, please contact us using the contact information provided below. Please note that we may require verification of your identity before processing your request.
                    </p>
                    <h5>5. Data Security:</h5>
                    <p>
                        <span>
                            We take reasonable measures to protect the security of your information and prevent unauthorized access, disclosure, or alteration. However, please be aware that no method of transmission over the internet or electronic storage
                            is completely secure, and we cannot guarantee the absolute security of your information.
                        </span>
                    </p>
                    <h5>6. Changes to this Privacy Policy:</h5>
                    <p>
                        <span>
                            We may update this Privacy Policy from time to time by posting the revised version on the Site. Your continued use of the Site after any changes to this Privacy Policy will constitute your acceptance of such changes.
                        </span>
                    </p>
                    <h5>7. Contact Us:</h5>
                    <p>
                        <span>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at info@fenetexscreens.com.</span>
                    </p>
                    <p></p>
                    <p>
                        <span>By using the Site, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.</span>
                    </p>
                </div>

            </div>
        </div>
      </div>
      </section>
      <Footer />
        </div>
    )
}
