const customRoute= !window.location.pathname.includes("/dealer")?{
    home:{
        path:"/",
        url:"/"
    },
    about:{
        path:"/about",
        url:"/about"
    },
    product:{
        path:"/products",
        url:"/products"
    },
    insect_screen:{
        path:"/product/insect-screen",
        url:"/product/insect-screen"
    },
    shade_screen:{
        path:"/product/shade-screen",
        url:"/product/shade-screen"
    },
    clear_vinyl:{
        path:"/product/clear-vinyl",
        url:"/product/clear-vinyl"
    },
    hurricane:{
        path:"/product/hurricane-screen",
        url:"/product/hurricane-screen"
    },
    commercial:{
        path:"/commercial",
        url:"/commercial"
    },
    contact:{
        path:"/contact",
        url:"/contact"
    },
    tech_sol:{
        path:"/tech-solution",
        url:"/tech-solution"
    },
    thank_you_1:{
        path:"/thank-you-1",
        url:"/thank-you-1"
    },
    thank_you_2:{
        path:"/thank-you-2",
        url:"/thank-you-2"
    },
    thank_you_3:{
        path:"/thank-you-3",
        url:"/thank-you-3"
    },
    thank_you_4:{
        path:"/thank-you-4",
        url:"/thank-you-4"
    },
    privacy_policy:{
        path:"/privacy-policy",
        url:"/privacy-policy"
    }
}:{
    home:{
        path:"/dealer/:id/",
        url:`/dealer/${window.location.pathname.split("/")[2]}/`
    },
    about:{
        path:"/dealer/:id/about",
        url:`/dealer/${window.location.pathname.split("/")[2]}/about`
    },
    product:{
        path:"/dealer/:id/products",
        url:`/dealer/${window.location.pathname.split("/")[2]}/products`
    },
    insect_screen:{
        path:"/dealer/:id/product/insect-screen",
        url:`/dealer/${window.location.pathname.split("/")[2]}/product/insect-screen`
    },
    shade_screen:{
        path:"/dealer/:id/product/shade-screen",
        url:`/dealer/${window.location.pathname.split("/")[2]}/product/shade-screen`
    },
    clear_vinyl:{
        path:"/dealer/:id/product/clear-vinyl",
        url:`/dealer/${window.location.pathname.split("/")[2]}/product/clear-vinyl`
    },
    hurricane:{
        path:"/dealer/:id/product/hurricane-screen",
        url:`/dealer/${window.location.pathname.split("/")[2]}/product/hurricane-screen`
    },
    commercial:{
        path:"/dealer/:id/commercial",
        url:`/dealer/${window.location.pathname.split("/")[2]}/commercial`
    },
    contact:{
        path:"/dealer/:id/contact",
        url:`/dealer/${window.location.pathname.split("/")[2]}/contact`
    },
    tech_sol:{
        path:"/dealer/:id/tech-solution",
        url:`/dealer/${window.location.pathname.split("/")[2]}/tech-solution`
    },
    thank_you_1:{
        path:"/dealer/:id/thank-you-1",
        url:`/dealer/${window.location.pathname.split("/")[2]}/thank-you-1`
    },
    thank_you_2:{
        path:"/dealer/:id/thank-you-2",
        url:`/dealer/${window.location.pathname.split("/")[2]}/thank-you-2`
    },
    thank_you_3:{
        path:"/dealer/:id/thank-you-3",
        url:`/dealer/${window.location.pathname.split("/")[2]}/thank-you-3`
    },
    thank_you_4:{
        path:"/dealer/:id/thank-you-4",
        url:`/dealer/${window.location.pathname.split("/")[2]}/thank-you-4`
    },
    privacy_policy:{
        path:"/dealer/:id/privacy-policy",
        url:`/dealer/${window.location.pathname.split("/")[2]}/privacy-policy`
    }
}

export default customRoute