import React from 'react';
import './Styles/App.css';
import Router from './Router'
import AppContext from "./AppContext";
import axios from 'axios';
import ReactGA from "react-ga4";
import reportWebVitals from './reportWebVitals';
import Commercial from './Containers/Commercial';

const dealerStatic=[
  {
      name:"Texas Shade Warehouse",
      url:"Texas-Shade-Warehouse",
      logo:require('./assets/images/companylogo/Texas-Shade-Warehouse-logo.png'),
      address1:"13202 North Hunters Circle",
      address2:"San Antonio, TX 78230",
      contact:"(210) 595-1091",
      about:[
          "Texas Shade Warehouse is your source for superior indoor and outdoor shading systems. Home and business owners across central and south Texas trust us to give their property a burst of style and comfort for a price that can’t be beat. With our stress-free customer service and unmatched workmanship, it’s easy to see why Texas Shade Warehouse is the number one Patio Screen Dealer for The Texas Hill Country and the Rio Grande Valley. We have proudly served San Antonio and the surrounding communities for 20 years!"
      ],
      gcode:"G-VMX39VNH7F",
      meta_tags:{
        home:"Texas Shade Warehouse: Authorized Dealer of Fenetex Screens. Transform your outdoor space with our high-quality screening solutions.",
        about:"About Us: Discover the story behind Texas Shade Warehouse, your trusted Fenetex Screens dealer. Learn about our commitment to excellence.",
        product:"All Products: Explore Texas Shade Warehouse's range of Fenetex Screens products. From insect screens to hurricane screens, we have it all.",
        insect:"Insect Screens: Keep insects out with Fenetex Screens' insect screens, available at Texas Shade Warehouse. Enjoy your space bug-free.",
        shade:"Shade Screens: Create a comfortable outdoor environment with Fenetex Screens' shade screens, offered by Texas Shade Warehouse.",
        clear_vinyl:"Clear Vinyl: Enjoy unobstructed views with Fenetex Screens' clear vinyl options, available at Texas Shade Warehouse. Stay protected with clarity.",
        hurrican:"Hurricane Screens: Protect your property from hurricanes with Fenetex Screens' hurricane screens, available at Texas Shade Warehouse.",
        commercial:"Screens for Commercial Use: Find durable Fenetex Screens for commercial applications at Texas Shade Warehouse. Protect your business with us.",
        contact:"Contact Us: Get in touch with Texas Shade Warehouse for all your Fenetex Screens needs. Our team is here to help you.",
        tech_solution:"Technology Solutions: Explore innovative Fenetex Screens technology solutions at Texas Shade Warehouse. Enhance your outdoor space with advanced features."
      }
  },
  {
      name:"B&H Shutters",
      url:"B-H-Shutters",
      logo:require('./assets/images/companylogo/BH-LOGO.jpg'),
      address1:"1660-B Cypress Drive",
      address2:"Jupiter, Fl 33469",
      contact:"(561) 746-0834",
      about:[
          "Located in Tequesta, Florida, B and H Shutters, Windows and Doors has been serving the area for over 40 years, producing high quality Hurricane Protection Products that reflect beauty and security for our valued clientele. We offer a variety of products that include Hurricane Shutters and Panels, Impact Fabric, Fenetex Rolldown Screens and Sunesta Products.",
          "Family owned and operated, we take great pride in offering the very best Hurricane Protection Systems in the market."
      ],
      gcode:"G-MNCMBSY08V",
      meta_tags:{
        home:"B&H Shutters: Authorized Dealer of Fenetex Screens. Transform your space with our premium screening solutions.",
        about:"About Us: Learn about B&H Shutters, your trusted Fenetex Screens dealer. Discover our commitment to quality and service.",
        product:"All Products: Explore B&H Shutters' range of Fenetex Screens products. From insect screens to hurricane screens, we have what you need.",
        insect:"Insect Screens: Keep bugs out with Fenetex Screens' insect screens, available at B&H Shutters. Enjoy your space bug-free.",
        shade:"Shade Screens: Create a comfortable outdoor space with Fenetex Screens' shade screens, offered by B&H Shutters.",
        clear_vinyl:"Clear Vinyl: Enjoy the view with Fenetex Screens' clear vinyl options, available at B&H Shutters. Stay protected without sacrificing visibility.",
        hurrican:"Hurricane Screens: Protect your property from hurricanes with Fenetex Screens' hurricane screens, offered by B&H Shutters.",
        commercial:"Screens for Commercial Use: Find durable Fenetex Screens for commercial applications at B&H Shutters. Protect your business with our solutions.",
        contact:"Contact Us: Get in touch with B&H Shutters for all your Fenetex Screens needs. Our team is ready to assist you.",
        tech_solution:"Technology Solutions: Explore innovative Fenetex Screens technology solutions at B&H Shutters. Enhance your space with advanced features."
      }
  },
  {
      name:"Wilmington Awning & Outdoor",
      url:"Wilmington-Awning-and-Outdoor",
      logo:require('./assets/images/companylogo/Wilimgton-Awning-logo.png'),
      address1:"730 Saint James Drive",
      address2:"Wilmington, NC 28403",
      contact:"(910) 799-2782",
      about:[
          "We are proud to be known as Wilmington’s premier awning, shutter, and shade company! Delivering quality products, expert installation and superior customer service we have served Wilmington and the surrounding area for over four decades. We offer a variety of products for homes, businesses and commercial buildings. Those products include quality custom fabric awnings, metal architectural canopies, decorative and hurricane storm shutters, clear curtains and shades. We also offer “push button” motorized retractable solutions for awnings, screens, shades and more.",
          "Each product comes in a wide variety of styles that is custom-tailored to your exact needs, and installed by our own trained and experienced team. All Wilmington Awning installers and staff are year-round, full-time employees, fully covered by Worker’s Compensation and Liability Insurance."
      ],
      gcode:"G-JM3EC4D1PE",
      meta_tags:{
        home:"Wilmington Awning & Outdoor: Authorized Dealer of Fenetex Screens. Enhance your outdoor space with our premium screening solutions.",
        about:"About Us: Discover the story behind Wilmington Awning & Outdoor, your trusted Fenetex Screens dealer. Learn about our commitment to quality and service.",
        product:"All Products: Explore Wilmington Awning & Outdoor's range of Fenetex Screens products. From insect screens to hurricane screens, we have you covered.",
        insect:"Insect Screens: Keep bugs at bay with Fenetex Screens' insect screens, available at Wilmington Awning & Outdoor. Enjoy your outdoor space in peace.",
        shade:"Shade Screens: Create a comfortable outdoor environment with Fenetex Screens' shade screens, offered by Wilmington Awning & Outdoor.",
        clear_vinyl:"Clear Vinyl: Enjoy the view while staying protected with Fenetex Screens' clear vinyl options, available at Wilmington Awning & Outdoor.",
        hurrican:"Hurricane Screens: Protect your home or business from hurricanes with Fenetex Screens' hurricane screens, offered by Wilmington Awning & Outdoor.",
        commercial:"Screens for Commercial Use: Find durable and reliable Fenetex Screens for commercial applications at Wilmington Awning & Outdoor. Protect your business today.",
        contact:"Contact Us: Get in touch with Wilmington Awning & Outdoor for all your Fenetex Screens needs. Our team is here to assist you.",
        tech_solution:"Technology Solutions: Discover innovative Fenetex Screens technology solutions at Wilmington Awning & Outdoor. Enhance your outdoor space with cutting-edge features."
      }
  },
  {
      name:"Titan Shutters and Screens",
      url:"Titan-Shutters-and-Screens",
      logo:require('./assets/images/titan-white.png'),
      address1:"59 West Ave St",
      address2:"Augustine, FL 32084",
      contact:"(904) 484-7580",
      about:[
          "Titan Shutters and Screens is your go-to dealer for upgrading outdoor spaces and protecting your home from storms. We are proud to offer top-of-the-line products from Fenetex, Struxure Pergola, Cover Your Pergola Inc., Sun Pro Awnings, Anchor Outdoor, Hurricane Fabric, AHT Shutters, and Unique Technology Custom Screen Doors.",
          "We are your one-stop shop to update your outdoor space. We deliver the highest quality installation and customer service possible. Based out of St. Augustine, we service Georgia to Port Saint Lucie. We have a professional team of skilled people to customize your perfect outdoor oasis."
      ],
      gcode:'G-RB0RE1XW4Z',
      meta_tags:{
        home:"Titan Shutters and Screens: Authorized Dealer of Fenetex Screens. Elevate your outdoor space with our premium screening solutions.",
        about:"About Us: Learn about Titan Shutters and Screens, your trusted Fenetex Screens dealer. Discover our commitment to quality and service.",
        product:"All Products: Explore Titan Shutters and Screens' range of Fenetex Screens products. From insect screens to hurricane screens, we have you covered.",
        insect:"Insect Screens: Keep bugs out with Fenetex Screens' insect screens, available at Titan Shutters and Screens. Enjoy a bug-free environment.",
        shade:"Shade Screens: Create a comfortable outdoor space with Fenetex Screens' shade screens, offered by Titan Shutters and Screens.",
        clear_vinyl:"Clear Vinyl: Enjoy clear views with Fenetex Screens' clear vinyl options, available at Titan Shutters and Screens. Stay protected with clarity.",
        hurrican:"Hurricane Screens: Protect your property from hurricanes with Fenetex Screens' hurricane screens, available at Titan Shutters and Screens.",
        commercial:"Screens for Commercial Use: Find durable Fenetex Screens for commercial applications at Titan Shutters and Screens. Protect your business with our solutions.",
        contact:"Contact Us: Get in touch with Titan Shutters and Screens for all your Fenetex Screens needs. Our team is here to assist you.",
        tech_solution:"Technology Solutions: Explore innovative Fenetex Screens technology solutions at Titan Shutters and Screens. Enhance your space with advanced features."
      }
  },
  {
      name:"Orlando Outdoor Living",
      url:"Orlando-Outdoor-Living",
      logo:require('./assets/images/companylogo/OOL-Logo1.png'),
      address1:"16016 New Independence Pkwy",
      address2:"Winter Garden, FL 34787",
      contact:"(407) 720-4774",
      about:[
          "Orlando Outdoor Living is a local, family-operated business, comprised of a team of highly qualified professionals who specialize in creating tailor-made outdoor living solutions. As a fully licensed and insured contractor, our team has extensive experience and expertise in designing, building, and installing a wide range of luxury amenities, including motorized screening, StruXure automated louvered pergolas, patio covers with insulated panel roofing, cabanas, outdoor kitchens, outdoor cooling and heating systems, paver patios, composite decks, artificial turf, outdoor furniture, and more!",
      ],
      gcode:'G-F3XGSZHG3D',
      meta_tags:{
        home:"Orlando Outdoor Living: Authorized Dealer of Fenetex Screens. Enhance your outdoor space with our premium screening solutions.",
        about:"About Us: Discover the story behind Orlando Outdoor Living, your trusted Fenetex Screens dealer. Learn about our commitment to quality and service.",
        product:"All Products: Explore Orlando Outdoor Living's range of Fenetex Screens products. From insect screens to hurricane screens, we have you covered.",
        insect:"Insect Screens: Keep bugs at bay with Fenetex Screens' insect screens, available at Orlando Outdoor Living. Enjoy your outdoor space in peace.",
        shade:"Shade Screens: Create a comfortable outdoor environment with Fenetex Screens' shade screens, offered by Orlando Outdoor Living.",
        clear_vinyl:"Clear Vinyl: Enjoy the view while staying protected with Fenetex Screens' clear vinyl options, available at Orlando Outdoor Living.",
        hurrican:"Hurricane Screens: Protect your home or business from hurricanes with Fenetex Screens' hurricane screens, offered by Orlando Outdoor Living.",
        commercial:"Screens for Commercial Use: Find durable and reliable Fenetex Screens for commercial applications at Orlando Outdoor Living. Protect your business today.",
        contact:"Contact Us: Get in touch with Orlando Outdoor Living for all your Fenetex Screens needs. Our team is here to assist you.",
        tech_solution:"Technology Solutions: Discover innovative Fenetex Screens technology solutions at Orlando Outdoor Living. Enhance your outdoor space with cutting-edge features."
      }
  }
]

const App=()=>{
  const [dealerData,setDealerData] = React.useState({})
  const [project,setProject]=React.useState([])
  const [dealer,setDealer]=React.useState({})
  const [dealerStatus,setDealerStatus] = React.useState(false)

  React.useEffect(()=>{
    if(project.length==0){
      let config = {
        headers: {
          "Access-Control-Allow-Headers" : "Access-Control-Allow-Headers",
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin":" *",
          authtoken: process.env.REACT_APP_API_TOKEN
        }
      }
      axios.get(process.env.REACT_APP_API_URL+"api/get_project_types/",
        config
      ).then(( response ) => {
        if([200,201].includes(response.status)){
          setProject(response.data)
        }
      })
      .catch((err)=>(
        console.log(err)
      ))
    }
    let pathname=window.location.pathname
    let companyname=pathname.split("/")[2]
    if(companyname?.toLowerCase()?.includes("wilmington-awning-&-outdoor")){
      window.location.replace("/dealer/Wilmington-Awning-and-Outdoor")
    }else if(companyname?.includes("wilmington-Awning-and-Outdoor")){
      window.location.replace("/dealer/Wilmington-Awning-and-Outdoor")
    }else if(companyname?.toLowerCase()?.includes("b&h-shutters")){
      window.location.replace("/dealer/B-H-Shutters")
    }
    let company=dealerStatic.find((d)=>d.url?.toLowerCase()?.includes(companyname?.toLowerCase()))
    if(companyname && pathname.includes("dealer")&&company){
      companyname=company.name
      if(Object.keys(dealer).length==0){
        let config = {
          headers: {
            "Access-Control-Allow-Headers" : "Access-Control-Allow-Headers",
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Methods":"POST,GET",
            "Access-Control-Allow-Origin":" *",
            authtoken: process.env.REACT_APP_API_TOKEN
          }
        }
        axios.get(process.env.REACT_APP_API_URL+"api/company_detail/"+companyname,
          config
        ).then(( response ) => {
          if([200,201].includes(response.status)){
            if(response.data.status=="Record Not Exist!"){
                // navigate("/")
                window.location.replace("/")
            }else{
                setDealer(response.data[0])
                setDealerStatus(true)
                let dealerd=dealerStatic.find((d)=>d.name.includes(response.data[0].company_name))
                setDealerData(dealerd)
            }
          }
        })
      }
    }else{
      if(companyname){
        window.location.replace("/")
      }
    }
  },[])
  React.useEffect(()=>{
    if(Object.keys(dealerData).length>0){
      ReactGA.initialize(dealerData?.gcode);
    }
  },[dealerData])
  return(
    <AppContext.Provider value={{
      project,
      setProject,
      dealer,
      dealerData,
      dealerStatus
    }}>
      <Router />
    </AppContext.Provider>
  )
}

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
reportWebVitals(SendAnalytics);

export default App;
