import React from 'react'
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import Home from './Containers/Home';
import { About } from './Containers/About';
import { Contact } from './Containers/Contact';
import { FenetexHome } from './Containers/FenetexHome';
import { CityHome } from './Containers/CityHome';
import { Products } from './Containers/Products';
import { InsectScreen } from './Containers/Products/InsectScreen';
import { ShadeScreen } from './Containers/Products/ShadeScreen';
import { ClearVinyl } from './Containers/Products/ClearVinyl';
import { HurricaneScreen } from './Containers/Products/HurricaneScreen';
import { TechSolution } from './Containers/TechSolution';
import Commercial from './Containers/Commercial';
import ScrollToTop from './Components/ScrollToTop';
import customRoute from './Components/CustomRoutes';
import { ThankYou } from './Containers/ThankYou';
import { PrivacyPolicy } from './Containers/PrivacyPolicy';

const Router =()=>{
  return(
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path={customRoute.home.path} element={customRoute.home.path.includes("/dealer") ? <Home /> : <FenetexHome />} />
          <Route path={"/city"} element={<CityHome />} />
          <Route path={customRoute.about.path} element={<About />} />
          <Route path={customRoute.contact.path} element={<Contact />} />
          <Route path={customRoute.product.path} element={<Products />} />
          <Route path={customRoute.insect_screen.path} element={<InsectScreen />} />
          <Route path={customRoute.shade_screen.path} element={<ShadeScreen />} />
          <Route path={customRoute.clear_vinyl.path} element={<ClearVinyl />} />
          <Route path={customRoute.hurricane.path} element={<HurricaneScreen />} />
          <Route path={customRoute.tech_sol.path} element={<TechSolution />} />
          <Route path={customRoute.commercial.path} element={<Commercial />} />
          <Route path={customRoute.commercial.path} element={<Commercial />} />
          <Route path={customRoute.thank_you_1.path} element={<ThankYou />} />
          <Route path={customRoute.thank_you_2.path} element={<ThankYou />} />
          <Route path={customRoute.thank_you_3.path} element={<ThankYou />} />
          <Route path={customRoute.thank_you_4.path} element={<ThankYou />} />
          <Route path={customRoute.privacy_policy.path} element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default Router
