import React from 'react';
import { Link } from 'react-router-dom';
import customRoute from './CustomRoutes';

export const CustomiseOption = (props) => {
  return (
    <section className="customization-sec">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-4 offset-lg-1">
                    <img src={require("../assets/images/controlRemoto.png")} className="img-fluid" alt=""/>
                </div>
                <div className="col-xl-6 col-lg-7">
                    <div className="section-title-light mb-3">
                        <h4 className="sub-heading">CUSTOMIZATION OPTIONS</h4>
                        <h3 className="heading">Let’s all agree - we want what we want, and whenever possible, we should get what we want.</h3>
                    </div>
                    <p>When you invest in your space, you’re going for a certain look. You should not have to compromise. <b>Fenetex offers a full range of metal colors, fabric styles and colors, as well as operational options</b> to set you up with the solution you seek.</p>
                    <div className="swatch-imgs">
                        <img src={require("../assets/images/Rectangle-4120.png")} alt=""/>
                        <img src={require("../assets/images/Rectangle-4121.png")} alt=""/>
                        <img src={require("../assets/images/Rectangle-4122.png")} alt=""/>
                        <img src={require("../assets/images/Rectangle-4123.png")} alt=""/>
                        <img src={require("../assets/images/Rectangle-4124.png")} alt=""/>
                        <img src={require("../assets/images/Rectangle-4125.png")} alt=""/>
                    </div>
                    <div className='section-btn' onClick={props.handleClick}>
                        {/* <Link to={customRoute.contact.url} className="btn btn-accent mt-5">Get a Free Estimate</Link> */}
                        <a className="btn btn-accent mt-5">Get a FREE Estimate</a>
                    </div>
                </div>
            </div>
        </div>      
    </section>
  )
}
