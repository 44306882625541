import React,{useContext} from 'react';
import AppContext from '../AppContext';

export const Topbar = () => {
    const {dealer,dealerData} = useContext(AppContext)
    return (
        <div id="top-bar">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-4 col-12">
                        {!window?.location?.pathname?.includes("/dealer")?(<a href="javascript:void(0);" className="topbar-btn btn btn-sm">Dealer Opportunities</a>):null}
                    </div>
                    <div className="col-lg-6 col-sm-8 col-12">
                        <div className="top-bar-right bt">
                            <ul className="find-location-ul">
                                <li className="find-location-li">
                                    {!window?.location?.pathname?.includes("/dealer")?(
                                        <>
                                            <form action="" method="GET">
                                                <div className="input-group zipcode-field ">
                                                    <input type="search" className="" placeholder="Enter Zip Code" />
                                                    <div className="input-group-append">
                                                    <i className="fa fa-search"></i>
                                                    </div>
                                                </div>
                                            </form>
                                            <a href="javascript:void(0);" className="find-location"><i className="fa fa-map-marker-alt"></i> Dealer Near me</a> 
                                        </>
                                    ):""}
                                </li>
                                {window?.location?.pathname?.includes("/dealer")?(
                                    <li><a href={`tel:${dealerData?.contact}`}><i className="fa fa-phone-alt"></i> {dealerData?.contact}</a></li>
                                ):""}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
