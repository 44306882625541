import React,{useState, useContext} from 'react';
import FenetexLogo from "../assets/images/Logo-fenetex.svg";
import { useNavigate } from 'react-router';
import {Link} from 'react-router-dom';
import customRoute from './CustomRoutes';
import AppContext from '../AppContext';

export const Header = () => {
  const navigate = useNavigate()
  const {dealer,dealerData,dealerStatus} = useContext(AppContext)
  const [open , setOpen] = useState(false)
    return (
        <nav className="navbar navbar-expand-lg main-header">
          <div className="container">
                <Link to={customRoute?.home?.url} className="logo">
                    <img src={FenetexLogo} alt=""/>
                    {dealerStatus?<p>By <span>{dealerData?.name}</span></p>:""}
                </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"><i className='fa fa-bars' /></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
                <ul className="navbar-nav text-center m-auto my-2 my-lg-0 navbar-nav-scroll align-items-center" style={{bsScrollHeight: "100px"}}>
                  <li className="nav-item">
                    <Link className={`nav-link ${window.location.pathname=="/"?'active':''}`} aria-current="page" to={customRoute.home.url}>Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${window.location.pathname=="/about"?'active':''}`} to={customRoute.about.url}>Innovation</Link>
                  </li>
                  <li className="nav-item dropdown">
                    <div className={`nav-link dropdown-toggle ${window.location.pathname=="/products"?'active':''}`}>
                      <Link to={customRoute.product.url}>Products</Link><span className='dropdown-toggle d-lg-none' onClick={() => setOpen(!open)}></span>
                    </div>
                    
                    <ul className={open?"dropdown-menu d-block":"dropdown-menu"}>
                      <li className='cursor-pointer'><Link className="dropdown-item" to={customRoute.insect_screen.url}>Insect Screens</Link></li>
                      <li className='cursor-pointer'><Link className="dropdown-item" to={customRoute.shade_screen.url}>Shade Screens</Link></li>
                      <li className='cursor-pointer'><Link className="dropdown-item" to={customRoute.clear_vinyl.url}>Clear Vinyl</Link></li>
                      <li className='cursor-pointer'><Link className="dropdown-item" to={customRoute.hurricane.url}>Hurricane Screens</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${window.location.pathname=="/commercial"?'active':''}`} to={customRoute.commercial.url}>Commercial</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${window.location.pathname=="/contact"?'active':''}`} to={customRoute.contact.url}>Contact</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${window.location.pathname=="/tech-solution"?'active':''}`} to={customRoute.tech_sol.url}>Technology Solutions</Link>
                  </li>
                </ul>
              <button className="btn btn-secondary" onClick={()=>navigate(customRoute.contact.url)}>Get a FREE Estimate</button>
            </div>
          </div>
        </nav>
    )
}
